.App {
  text-align: center;
  padding:10px;
}

.AppInternal{
    text-align: left;
}

.AssetName{
    padding: 10px;
    background: #000;
    color: #FFF;
    font-size:1.25em;
}

.AssetReturned{
    padding:10px;
    background: #efefef;
    color: #000;
}

.Instructions{
    padding: 10px 10px 30px 10px;
    font-size: 0.8em;
    background: #000;
    color: #aeaeae;
}

.nameID{
    font-weight: bold;
    text-transform:uppercase;
    margin-right:10px;
}

.returnedID{
    font-weight: bold;
    margin-right:10px;
    text-transform:uppercase;   
}

.reports{
    padding:10px;
    color: #FFF;
}

.leftColumn{
    width:50%;
    float:left;
    text-align:center;
    
}

.rightColumn{
    width:50%;
    text-align:center;
    float:left;
}

.footnote{
    font-size:0.8em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.status{
    line-height: 34px;
    border-bottom: 1px solid #aeaeae;
}

.switch {
    position: relative;
    display: inline-block;
    width: 124px;
    height: 34px;
    margin:10px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(204, 13, 13);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #04c434;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #04c434;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(90px);
    -ms-transform: translateX(90px);
    transform: translateX(90px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

.slider:after
{
 content:'EMPTY';
 color: white;
 display: block;
 position: absolute;
 transform: translate(-50%,-50%);
 top: 50%;
 left: 50%;
 font-size: 10px;
 font-family: Verdana, sans-serif;
}

input:checked + .slider:after
{  
  content:'FULL';
}

.btn {
    font-size: 1em;
    line-height: 1em;
    letter-spacing: 0.04em;
    display: inline-block;
  }
  .btn--svg {
    position: relative;
    height: 42px;
    width: 100%;
    overflow: hidden;
    border-radius: 21px;
  }
  .btn--svg:hover .btn--svg__circle circle {
    transform: scale(0);
  }
  .btn--svg:hover .btn--svg__label {
    color: #fff;
  }
  .btn--svg:hover .btn--svg__border--left path,
  .btn--svg:hover .btn--svg__border--right path {
    stroke-dasharray: 61.8204345703 61.8204345703;
    stroke-dashoffset: 0;
    -webkit-transition-delay: 0.25s;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-in-out;
    -webkit-transition-property: stroke-dashoffset;
    -moz-transition-delay: 0.25s;
    -moz-transition-duration: 0.5s;
    -moz-transition-timing-function: ease-in-out;
    -moz-transition-property: stroke-dashoffset;
    -ms-transition-delay: 0.25s;
    -ms-transition-duration: 0.5s;
    -ms-transition-timing-function: ease-in-out;
    -ms-transition-property: stroke-dashoffset;
    transition-delay: 0.25s;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    transition-property: stroke-dashoffset;
  }
  .btn--svg__label {
    -webkit-font-smoothing: antialiased;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    color: black;
    z-index: 3;
    width: 100%;
    transition: color 0.5s ease-in-out;
  }
  .btn--svg__circle circle {
    -webkit-transition: transform 0.5s ease-in-out;
    -webkit-transform: scale(1.1);
    -webkit-transform-origin: 50% 50%;
    -moz-transition: transform 0.5s ease-in-out;
    -moz-transform: scale(1.1);
    -moz-transform-origin: 50% 50%;
    -ms-transition: transform 0.5s ease-in-out;
    -ms-transform: scale(1.1);
    -ms-transform-origin: 50% 50%;
    transition: transform 0.5s ease-in-out;
    transform: scale(1.1);
    transform-origin: 50% 50%;
  }
  .btn--svg__border--left path, .btn--svg__border--right path {
    stroke-dasharray: 61.8204345703 61.8204345703;
    -webkit-transition-duration: 0s;
    -webkit-transition-timing-function: ease-in-out;
    -webkit-transition-property: stroke-dashoffset;
    -webkit-transition-delay: 0.5s;
    -moz-transition-duration: 0s;
    -moz-transition-timing-function: ease-in-out;
    -moz-transition-property: stroke-dashoffset;
    -moz-transition-delay: 0.5s;
    -ms-transition-duration: 0s;
    -ms-transition-timing-function: ease-in-out;
    -ms-transition-property: stroke-dashoffset;
    -ms-transition-delay: 0.5s;
    transition-duration: 0s;
    transition-timing-function: ease-in-out;
    transition-property: stroke-dashoffset;
    transition-delay: 0.5s;
  }
  .btn--svg__border--left path {
    stroke-dashoffset: -61.8204345703;
  }
  .btn--svg__border--right path {
    stroke-dashoffset: 61.8204345703;
  }
  .btn--svg svg, .btn--svg__label {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -webkit-transform-origin: 50% 50%;
    -moz-transform: translate(-50%, -50%);
    -moz-transform-origin: 50% 50%;
    -ms-transform: translate(-50%, -50%);
    -ms-transform-origin: 50% 50%;
    transform: translate(-50%, -50%);
    transform-origin: 50% 50%;
  }

  .submitReport{
      clear: both;
      padding-left:10px;
      padding-right:10px;
      padding-top:35px;
      padding-bottom:25px;
      display:block;
      text-align: center;
  }

  .final{
      text-align: center;
      padding:10px;
  }